<template>
  <v-card class="pa-3 dpd-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Others</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="detail.references.customsHandlingCategory"
          :items="dataStore.customsHandlingCategories"
          item-text="value"
          item-value="key"
          hide-details
          dense
          label="Category"
          outlined
          >Color</v-select
        >
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="detail.placeholder.jobHandled"
          label="Job handled"
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary">Check ATB number</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}
defineProps<IProps>();
const dataStore = useDataStore();
</script>
