<template>
  <v-container fluid class="shipment-details">
    <v-row>
      <v-col cols="12" class="pa-0">
        <form>
          <v-navigation-drawer
            v-model="drawer"
            height="100vh"
            absolute
            temporary
            width="20%"
          >
            <v-list nav dense>
              <v-list-item-group v-model="group" class="justify-space-between">
                <v-list-item>
                  <v-text-field
                    v-model="fieldFilterValue"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-list-item>
                <draggable v-model="visibleHeaders">
                  <v-list-item
                    v-for="(field, index) in visibleHeaders"
                    :key="index"
                  >
                    <v-checkbox
                      v-model="field.visible"
                      class="mr-5"
                      :label="field.text"
                    ></v-checkbox>
                  </v-list-item>
                </draggable>
              </v-list-item-group>
            </v-list>
            <template #append>
              <div class="pa-2">
                <v-btn
                  block
                  class="mb-2"
                  color="error"
                  :disabled="savingShipmentHeaderColumns"
                  @click="cancelShipmentHeaderColumns"
                >
                  Cancel
                </v-btn>
                <v-btn
                  block
                  color="success"
                  :disabled="savingShipmentHeaderColumns"
                  @click="saveShipmentHeaderColumns"
                >
                  Save
                </v-btn>
              </div>
            </template>
          </v-navigation-drawer>
          <v-data-table
            v-model="selected"
            :headers="visibleTableHeaders"
            :items="filteredShipmentDetails"
            :show-select="true"
            hide-default-header
            hide
            :single-select="false"
            :server-items-length="totalAmountOfItems"
            :options.sync="options"
            :footer-props="footerOptions"
            item-key="id"
            height="calc(100vh - 262px)"
            fixed-header
            fixed-footer
            class="elevation-1"
            :loading="isLoading"
            :search="search"
            @update:options="onUpdateOptions"
          >
            <template #top>
              <v-expansion-panels
                flat
                accordion
                class="shipment-details__filters"
              >
                <v-expansion-panel color="primary">
                  <v-expansion-panel-header
                    ><span class="text-h6">Filters</span>
                    <template #actions>
                      <v-icon color="white"> $expand </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-toolbar flat extended height="auto">
                      <v-container fluid>
                        <v-row>
                          <v-col class="py-1" cols="4">
                            <v-card-title>General info</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.invoiceNumber"
                                    hide-details
                                    dense
                                    label="invoice number"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <span class="font-weight-bold">
                                    Multiple invoice number search
                                    <FilterParcels
                                      v-model="filter.invoiceNumbers"
                                    ></FilterParcels>
                                  </span>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-select
                                    v-model="filter.status"
                                    :items="ddlStatusses"
                                    hide-details
                                    dense
                                    item-value="key"
                                    item-text="value"
                                    label="Status"
                                    outlined
                                    clearable
                                  ></v-select>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.parcel"
                                    hide-details
                                    dense
                                    label="Parcel ID"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <span class="font-weight-bold">
                                    Multiple parcel ID search
                                    <FilterParcels
                                      v-model="filter.parcels"
                                    ></FilterParcels>
                                  </span>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-select
                                    v-model="filter.generalCustomerId"
                                    :items="dataStore.generalCustomers"
                                    hide-details
                                    dense
                                    item-text="shortName"
                                    item-value="id"
                                    label="General customer"
                                    outlined
                                    clearable
                                  ></v-select>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.hawbnUmber"
                                    hide-details
                                    dense
                                    label="HAWB"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.mawbnUmber"
                                    hide-details
                                    dense
                                    label="MAWB"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.exportMrn"
                                    hide-details
                                    dense
                                    label="MRN"
                                    outlined
                                  ></v-text-field>
                                </v-col>

                                <v-col class="py-1" cols="4">
                                  <DatePicker
                                    v-model="filter.arrivalDate"
                                    type="date"
                                    label="Arrival date"
                                    hide-details
                                    dense
                                    outlined
                                  ></DatePicker>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-select
                                    v-model="filter.importExport"
                                    :items="ddlImportExport"
                                    hide-details
                                    dense
                                    item-value="key"
                                    item-text="value"
                                    label="Import/Export"
                                    outlined
                                    clearable
                                  ></v-select
                                ></v-col>
                              </v-row>
                            </v-card-text>
                          </v-col>
                          <v-divider class="my-5" vertical></v-divider>
                          <v-col class="py-1" cols="4">
                            <v-card-title>Milestones</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col class="py-1" cols="4">
                                  <v-select
                                    v-model="filter.lastMilestone.id"
                                    :items="dataStore.milestoneCustomsHandling"
                                    hide-details
                                    dense
                                    item-value="milestoneId"
                                    item-text="milestoneName"
                                    label="Last milestone"
                                    outlined
                                    clearable
                                    @change="onLastMilestoneSelected"
                                  ></v-select>
                                </v-col>
                                <v-col cols="8">
                                  <v-row>
                                    <v-col class="py-1">
                                      <DatePicker
                                        v-model="filter.lastMilestone.fromDate"
                                        type="date"
                                        label="From"
                                        hide-details
                                        dense
                                        outlined
                                      ></DatePicker>
                                    </v-col>
                                    <v-col class="py-1">
                                      <DatePicker
                                        v-model="filter.lastMilestone.tillDate"
                                        type="date"
                                        label="Till"
                                        hide-details
                                        dense
                                        outlined
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-select
                                    v-model="filter.hasMilestone.id"
                                    :items="dataStore.milestoneCustomsHandling"
                                    hide-details
                                    dense
                                    item-value="milestoneId"
                                    item-text="milestoneName"
                                    label="Has milestone"
                                    outlined
                                    clearable
                                    @change="onHasMilestoneSelected"
                                  ></v-select>
                                </v-col>
                                <v-col cols="8">
                                  <v-row>
                                    <v-col class="py-1">
                                      <DatePicker
                                        v-model="filter.hasMilestone.fromDate"
                                        type="date"
                                        label="From"
                                        hide-details
                                        dense
                                        outlined
                                      ></DatePicker>
                                    </v-col>
                                    <v-col class="py-1">
                                      <DatePicker
                                        v-model="filter.hasMilestone.tillDate"
                                        type="date"
                                        label="Till"
                                        hide-details
                                        dense
                                        outlined
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-col>
                          <v-divider class="my-5" vertical></v-divider>
                          <v-col class="py-1" cols="4">
                            <v-card-title
                              >Sender / Shipper / Receiver</v-card-title
                            >
                            <v-card-text>
                              <v-row>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.daysPass"
                                    type="number"
                                    hide-details
                                    dense
                                    label="days passed w/o clearance"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-divider horizontal></v-divider>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.senderName"
                                    hide-details
                                    dense
                                    label="sender name"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.consigneeName"
                                    hide-details
                                    dense
                                    label="cosignee name "
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.consigneeEORI"
                                    hide-details
                                    dense
                                    label="consignee EORI"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.shipperCity"
                                    hide-details
                                    dense
                                    label="shipper city"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.shipperCountry"
                                    hide-details
                                    dense
                                    label="shipper country"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.shipperZipcode"
                                    hide-details
                                    dense
                                    label="shipper zipcode"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.receiverCity"
                                    hide-details
                                    dense
                                    label="receiver city"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.receiverCountry"
                                    hide-details
                                    dense
                                    label="receiver country"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                                <v-col class="py-1" cols="4">
                                  <v-text-field
                                    v-model="filter.receiverZipcode"
                                    hide-details
                                    dense
                                    label="receiver zipcode"
                                    outlined
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn
                              class="ms-3 me-2 mb-3"
                              color="error"
                              @click="clearForm()"
                            >
                              Clear
                            </v-btn>
                            <v-btn
                              class="mb-3"
                              color="success"
                              @click="getShipmentDetails()"
                            >
                              Search
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-toolbar>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row class="grey lighten-4 py-5">
                <v-col>
                  <v-btn
                    class="ms-3 me-2"
                    color="primary"
                    @click="drawer = true"
                  >
                    Manage fields
                    <v-icon small>mdi-filter</v-icon>
                  </v-btn>
                  <v-btn class="ms-3" color="primary">
                    Add shipment
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="!isSelected"
                    target="_blank"
                    class="ms-3"
                    color="primary"
                    @click="showShipmentDetails()"
                  >
                    View shipments
                    <v-icon small>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn target="_blank" class="ms-3" color="primary">
                    Export data
                    <v-icon small>mdi-export</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="py-1" cols="4">
                  <v-text-field
                    v-model="search"
                    class="pa-2 me-4 grey lighten-2"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template #header="{ props }">
              <thead class="v-data-table-header">
                <tr>
                  <th scope="col"></th>
                  <th
                    v-for="header in visibleTableHeaders"
                    :key="header.value ?? ''"
                    scope="col"
                    style="position: relative"
                  >
                    {{ header.text }}
                    <div
                      v-if="header.useFilter"
                      style="
                        position: absolute;
                        height: 16px;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        right: 0;
                      "
                    >
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-y
                        transition="slide-y-transition"
                        left
                        fixed
                        style="position: absolute; right: 0"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            :color="hasActiveFilters(header) ? 'red' : 'black'"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-filter-variant
                          </v-icon>
                        </template>
                        <v-list flat dense class="pa-0">
                          <v-list-item-group multiple class="py-2">
                            <template v-for="(item, i) in header.values">
                              <v-list-item>
                                <v-list-item-action>
                                  <v-checkbox
                                    v-model="item.isChecked"
                                    color="primary"
                                    dense
                                  ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="item.value"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </div>
                  </th>
                </tr>
              </thead>
            </template>
          </v-data-table>
          <v-snackbar v-model="showSuccessMessage" :timeout="5000">
            {{ successMessage }}
            <template #action="{ attrs }">
              <v-btn
                color="success"
                v-bind="attrs"
                @click="showSuccessMessage = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ToolbarItem } from "@/models/ToolbarItem";
import {
  ShipmentDetailSearchRequest,
  ShipmentDetailApi,
  ShipmentDetailColumnViewModel,
  ShipmentDetailMileStoneFilter,
  ShipmentDetailVisibleColumnsViewModel,
  Int32StringKeyValueItem,
} from "@/openapi";
import DatePicker from "@/components/editors/DatePicker.vue.html";
import FilterParcels from "@/components//FilterParcels.vue.html";
import { emitError } from "@/event-bus";
import { DataOptions, DataTableHeader } from "vuetify";
import { useDataStore } from "@/stores/data-store";
import { FooterOptions } from "@/types/types";
import { ValidationObserver } from "vee-validate";
import draggable from "vuedraggable";
import { computed, ref, onMounted } from "vue";
import moment from "moment";
import router from "@/router";

interface IFilters {
  step?: string | null;
  shipmentStatusDescription?: number | null;
  parcel?: string | null;
  parcels?: string | null;
  exportMrn?: string | null;
  consigneeCountry?: string | null;
  invoiceNumber?: string | null;
  invoiceNumbers?: string | null;
  hawbnUmber?: string | null;
  mawbnUmber?: string | null;
  status?: number | null;
  generalCustomerId?: number | null;
  arrivalDate?: Date | null;
  importExport?: number | null;
  lastMilestone: ShipmentDetailMileStoneFilter;
  hasMilestone: ShipmentDetailMileStoneFilter;
  daysPass?: number | null;
  senderName?: string | null;
  consigneeName?: string | null;
  consigneeEORI?: string | null;
  receiverCity?: string | null;
  receiverCountry?: string | null;
  receiverZipcode?: string | null;
  shipperCity?: string | null;
  shipperZipcode?: string | null;
  shipperCountry?: string | null;
}

const dataStore = useDataStore();
const api = new ShipmentDetailApi(undefined, "");

const emits = defineEmits(["PageInfoReceived"]);

const isLoading = ref(false);
const selected = ref<ShipmentDetailColumnViewModel[]>([]);
const savingShipmentHeaderColumns = ref(false);
const showSuccessMessage = ref(false);
const successMessage = ref("");
const filter = ref<IFilters>({
  step: null,
  shipmentStatusDescription: null,
  parcel: null,
  parcels: null,
  exportMrn: null,
  shipperCountry: null,
  consigneeCountry: null,
  invoiceNumber: null,
  invoiceNumbers: null,
  hawbnUmber: null,
  mawbnUmber: null,
  status: null,
  generalCustomerId: null,
  arrivalDate: null,
  importExport: null,
  lastMilestone: {
    id: null,
    fromDate: null,
    tillDate: null,
  },
  hasMilestone: {
    id: null,
    fromDate: null,
    tillDate: null,
  },
  daysPass: null,
  senderName: null,
  consigneeName: null,
  consigneeEORI: null,
  receiverCity: null,
  receiverCountry: null,
  receiverZipcode: null,
  shipperCity: null,
  shipperZipcode: null,
});
const fieldFilterValue = ref("");
const filterDefault = structuredClone(filter.value);
const visibleHeaders = ref<ShipmentDetailVisibleColumnsViewModel[]>([]);
const visibleHeadersClone = ref<ShipmentDetailVisibleColumnsViewModel[]>([]);
const ddlImportExport = ref<Int32StringKeyValueItem[]>([]);
const ddlStatusses = ref<Int32StringKeyValueItem[]>([]);
const ddlMilestones = ref<Int32StringKeyValueItem[]>([]);

const search = ref("");

const shipments = ref<ShipmentDetailColumnViewModel[]>([]);

const drawer = ref(false);
const group = ref(null);

const options = ref<DataOptions>({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});

const footerOptions = ref<FooterOptions>({
  showFirstLastPage: true,
  itemsPerPageOptions: [100, 1000, 5000, 10000],
  disablePagination: false,
});

const totalAmountOfItems = ref(0);

const sortBy = computed(() => {
  return options.value.sortBy?.[0] ?? "";
});

const sortDesc = computed(() => {
  return options.value.sortDesc?.[0] ?? false;
});

const visibleTableHeaders = computed(() => {
  return visibleHeaders.value.filter((header) => header.visible);
});

const isSelected = computed(() => selected.value.length > 0);

const hasActiveFilters = computed(() => {
  return (header: ShipmentDetailVisibleColumnsViewModel) =>
    header.values?.some((item) => item.isChecked);
});

onMounted(() => {
  getDropdowns();
  getShipmentColumns();
});

const onLastMilestoneSelected = () => {
  if (filter.value.lastMilestone.id) {
    if (!filter.value.lastMilestone.fromDate) {
      const fromDate = moment(new Date()).add(-1, "M").format("YYYY-MM-DD");
      filter.value.lastMilestone.fromDate = fromDate;
    }
    if (!filter.value.lastMilestone.tillDate) {
      const tillDate = moment(new Date()).format("YYYY-MM-DD");
      filter.value.lastMilestone.tillDate = tillDate;
    }
  } else {
    filter.value.lastMilestone.fromDate = null;
    filter.value.lastMilestone.tillDate = null;
  }
};

const onHasMilestoneSelected = () => {
  if (filter.value.hasMilestone.id) {
    if (!filter.value.hasMilestone.fromDate) {
      const fromDate = moment(new Date()).add(-1, "M").format("YYYY-MM-DD");
      filter.value.hasMilestone.fromDate = fromDate;
    }
    if (!filter.value.hasMilestone.tillDate) {
      const tillDate = moment(new Date()).format("YYYY-MM-DD");
      filter.value.hasMilestone.tillDate = tillDate;
    }
  } else {
    filter.value.hasMilestone.fromDate = null;
    filter.value.hasMilestone.tillDate = null;
  }
};

const clearForm = () => {
  filter.value = structuredClone(filterDefault);
};

const cancelShipmentHeaderColumns = () => {
  visibleHeaders.value = structuredClone(visibleHeadersClone.value);
  fieldFilterValue.value = "";
  drawer.value = false;
};

const saveShipmentHeaderColumns = async () => {
  try {
    savingShipmentHeaderColumns.value = true;
    await api.saveShipmentDetailVisibleColumns(visibleHeaders.value);
    visibleHeadersClone.value = structuredClone(visibleHeaders.value);
    savingShipmentHeaderColumns.value = false;
    fieldFilterValue.value = "";
    drawer.value = false;
    refresh();
  } catch (e) {
    emitError("Something went wrong while saving the visible column headers");
  }
};

const getShipmentColumns = async () => {
  const response = await api.getShipmentVisibleColumns();
  visibleHeaders.value = response.data ?? [];
  visibleHeadersClone.value = structuredClone(response.data ?? []);
};

const getDropdowns = async () => {
  dataStore.fetchGeneralCustomers();
  dataStore.fetchMilestoneCustomsHandling();
  ddlStatusses.value = [
    { key: 0, value: "Only Active" },
    { key: 1, value: "Only Fulfilled" },
    { key: 2, value: "Only Cancelled" },
    { key: 3, value: "Only Invoiced" },
    { key: 4, value: "All" },
    { key: 5, value: "Only Template" },
  ];
  ddlImportExport.value = [
    { key: 1, value: "Import" },
    { key: 2, value: "Export" },
  ];
};

const filteredShipmentDetails = computed(() => {
  return shipments.value.filter((detail) => {
    const entries = Object.entries(detail);
    let found = true;
    entries.forEach(([key, value]) => {
      const column = visibleHeaders.value.find((c) => c.columnName == key);
      if (found && !!column) {
        const checked = column?.values?.filter((filter) => filter.isChecked);
        if (!!checked && checked.length > 0) {
          var result = checked.filter(
            (item) => item.searchValue == value?.toString().toLowerCase(),
          );
          if (result.length == 0) {
            found = false;
          }
        }
      }
    });
    if (found && search.value) {
      if (
        (detail.searchText?.indexOf(search.value.toLowerCase()) ?? -1) == -1
      ) {
        found = false;
      }
    }
    return found;
  });
});

const getShipmentDetails = async (page = options.value.page) => {
  isLoading.value = true;
  //await getShipmentColumns();
  try {
    let daysPass = null;
    if (filter.value.daysPass != null && filter.value.daysPass != undefined) {
      daysPass = Math.abs(filter.value.daysPass);
    }
    if (daysPass != null && daysPass < 1) {
      daysPass = 0;
    }
    const response = await api.searchShipments({
      step: filter.value.step,
      shipmentStatusDescription: filter.value.shipmentStatusDescription,
      parsel: filter.value.parcel,
      parcels: filter.value.parcels?.split(/[\n\r\s,]+/),
      exportMrn: filter.value.exportMrn,
      shipperCountry: filter.value.shipperCountry,
      consigneeCountry: filter.value.consigneeCountry,
      invoiceNumbers: filter.value.invoiceNumbers,
      invoiceNumber: filter.value.invoiceNumber,
      hawbnUmber: filter.value.hawbnUmber,
      mawbnUmber: filter.value.mawbnUmber,
      status: filter.value.status,
      generalCustomerId: filter.value.generalCustomerId,
      arrivalDate: filter.value.arrivalDate,
      import: filter.value.importExport == 1,
      export: filter.value.importExport == 2,
      lastMilestone: filter.value.lastMilestone,
      hasMilestone: filter.value.hasMilestone,
      senderName: filter.value.senderName,
      consigneeName: filter.value.consigneeName,
      consigneeEORI: filter.value.consigneeEORI,
      receiverCity: filter.value.receiverCity,
      receiverCountry: filter.value.receiverCountry,
      receiverZipcode: filter.value.receiverZipcode,
      shipperCity: filter.value.shipperCity,
      shipperZipcode: filter.value.shipperZipcode,
      search: search.value,
      sortBy: sortBy.value,
      sortDesc: sortDesc.value,
      daysPass: daysPass,
      page: page,
      itemsPerPage: options.value.itemsPerPage,
    } as ShipmentDetailSearchRequest);
    shipments.value = response.data?.results ?? [];
    visibleHeaders.value = response.data.columns ?? [];
    visibleHeadersClone.value = structuredClone(response.data.columns ?? []);
    totalAmountOfItems.value = response.data?.resultsCount ?? 0;
  } catch (e) {
    emitError("Something went wrong while retrieving the schemes");
  }
  isLoading.value = false;
};

const showShipmentDetails = () => {
  const next = {
    name: "ShipmentDetailsPage",
    params: {
      shipmentIds: JSON.stringify(selected.value.map((item) => item.id)),
    },
  };
  router.push(next);
};

const onUpdateOptions = () => {
  if (shipments.value.length) {
    getShipmentDetails();
  }
};

const refresh = () => {
  getShipmentDetails();
};

var toolbarButtons: ToolbarItem[] = [
  {
    callback: () => refresh(),
    icon: "mdi-refresh",
    tooltipText: "Refresh overview",
  },
];
emits("PageInfoReceived", "Shipment details", toolbarButtons);
</script>

<style lang="scss">
.shipment-details .v-expansion-panel-content__wrap {
  padding: 0;
}

.shipment-details th:not(:first-child) .v-input {
  display: none;
}

.shipment-details .shipment-details__filters .v-expansion-panel-header {
  border: 1px solid #424242;
  border-bottom: 0;
  margin: 10px 35px 0 35px;
  width: auto;
  min-height: auto;
  background-color: #424242;
  color: #fff;
  padding: 10px 25px 10px 25px;
}

.shipment-details .shipment-details__filters .v-toolbar__extension {
  display: none;
}

.shipment-details .shipment-details__filters .v-expansion-panel-content {
  border-top: 1px solid rgb(66, 66, 66);
  border-bottom: 1px solid rgb(66, 66, 66);
}
</style>
