<template>
  <v-card>
    <validation-observer v-slot="{ invalid }">
      <v-card-title class="text-h5 grey lighten-2"> Documents </v-card-title>
      <v-col>
        <v-autocomplete
          v-model="currentArticle.article"
          :items="dataStore.customsArticles"
          item-text="description"
          item-value="description"
          hide-details
          dense
          label="Artikel"
          outlined
        ></v-autocomplete
      ></v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.wtn"
          hide-details
          dense
          label="WTN"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.additionalCode"
          hide-details
          dense
          label="Zusatzcode"
          outlined
        ></v-text-field>
      </v-col>

      <v-col class="col-12">
        <v-text-field
          v-model="currentArticle.goodsDescription"
          hide-details
          dense
          label="Warenbeschreibung"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.rcTariff"
          hide-details
          dense
          label="RC tarif"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.scTariff"
          hide-details
          dense
          label="SC tarif"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.netMass"
          hide-details
          dense
          label="Eigenmasse"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.rawMass"
          hide-details
          dense
          label="Rohmasse"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.invoicePrice"
          hide-details
          dense
          label="Rechnungspreis"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="currentArticle.billingCurrency"
          :items="dataStore.exchangeRateCurrencies"
          :item-text="getCurrencyText"
          item-value="name"
          hide-details
          dense
          label="Rechnungswährung"
          outlined
        ></v-select
      ></v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.itemPriceEur"
          hide-details
          dense
          label="Artikelpreis (EUR)"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.freightCharges"
          hide-details
          dense
          label="Frachtkosten"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="currentArticle.freightCostCurrency"
          :items="dataStore.exchangeRateCurrencies"
          :item-text="getCurrencyText"
          item-value="name"
          hide-details
          dense
          label="Frachtkostenwährung"
          outlined
        ></v-select
      ></v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.customsValueEur"
          hide-details
          dense
          label="Zollwert (EUR)"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.statWert"
          hide-details
          dense
          label="Stat. Wert"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.statAmount"
          hide-details
          dense
          label="Stat. Merge"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        {{ currentArticle.statAmountCode }}
        <v-select
          v-model="currentArticle.statAmountCode"
          :items="dataStore.measurementUnits"
          item-text="description"
          item-value="code"
          hide-details
          dense
          label="Masseinheit"
          outlined
        ></v-select
      ></v-col>
      <v-col>
        <v-text-field
          v-model="currentArticle.customsAmount"
          hide-details
          dense
          label="Zollmerge"
          type="number"
          step=".01"
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="currentArticle.customsAmountCode"
          :items="dataStore.measurementUnits"
          item-text="description"
          item-value="code"
          hide-details
          dense
          label="Masseinheit"
          outlined
          ><template slot="selection" slot-scope="data">
            {{ data.item.code }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template></v-select
        ></v-col
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="saving || invalid"
          :loading="saving"
          text
          @click="save()"
        >
          Save
        </v-btn>
        <v-btn color="primary" :disabled="saving" text @click="closeDialog()">
          Close
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script setup lang="ts">
import { ref, onBeforeMount } from "vue";
import { useDataStore } from "@/stores/data-store";
import { ShipmentDetailAcArticleModel, CurrencyViewModel } from "@/openapi";

const dataStore = useDataStore();

interface IProps {
  article: ShipmentDetailAcArticleModel;
  index: number | null;
  saving: boolean;
}
const props = defineProps<IProps>();
const emit = defineEmits(["close", "save"]);
const currentArticle = ref<ShipmentDetailAcArticleModel>({ id: 0 });
const getCurrencyText = (item: CurrencyViewModel) => {
  return `${item.name} - ${item.description}`;
};
const closeDialog = () => {
  emit("close");
};

const save = () => {
  emit("save", currentArticle.value, props.index);
};

onBeforeMount(() => {
  currentArticle.value = structuredClone(props.article);
});
</script>

<style lang="scss" scoped>
.documents-table {
  .max-200 {
    width: 200px;
  }
}
</style>
