<template>
  <v-card class="pa-3 clearance-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Automatic clearance</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="5">
            <span class="mt-5 pe-2">Documents required:</span>
            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="detail.automaticClearance.docReqInvoice"
                  class="me-2"
                  label="Invoice"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="detail.automaticClearance.docReqProofOfPayment"
                  class="me-2"
                  label="Proof Payment"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="detail.automaticClearance.docReqTravelDocs"
                  class="me-2"
                  label="Travel docs"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <span class="mt-5 pe-2">Documents required after inspection:</span>
            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="detail.automaticClearance.docReqAIInvoice"
                  label="Invoice"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="detail.automaticClearance.docReqAIProofOfPayment"
                  label="Proof Payment"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="detail.automaticClearance.docReqAITravelDocs"
                  label="Travel docs"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="detail.placeholder.connectedSchemeId"
              :items="dataStore.customsSchemes"
              item-text="value"
              item-value="key"
              hide-details
              dense
              label="Connected scheme:"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-textarea
          v-model="detail.automaticClearance.content"
          label="Content"
          outlined
          hide-details
          auto-grow
          row-height="10"
        ></v-textarea>
      </v-col>
      <v-col cols="4">
        <v-textarea
          v-model="detail.automaticClearance.articleCondition"
          label="Article condition"
          outlined
          hide-details
          auto-grow
          row-height="10"
        ></v-textarea>
      </v-col>

      <v-col cols="4">
        <v-textarea
          v-model="detail.automaticClearance.importReason"
          label="Reason for import"
          outlined
          hide-details
          auto-grow
          row-height="10"
        ></v-textarea>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="detail.automaticClearance.valueOfGoods"
              label="Value of goods"
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="detail.automaticClearance.valueOfGoodsCurrency"
              :items="dataStore.currencies"
              item-text="name"
              item-value="name"
              hide-details
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="detail.automaticClearance.freightCosts"
              label="Freight costs"
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="detail.automaticClearance.freightCostCurrency"
              :items="dataStore.currencies"
              item-text="name"
              item-value="name"
              hide-details
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="detail.automaticClearance.paymentValue"
              label="Payment Value"
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="detail.automaticClearance.paymentValueCurrency"
              :items="dataStore.currencies"
              item-text="name"
              item-value="name"
              hide-details
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Pin code"
              outlined
              hide-details
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="detail.automaticClearance.color"
              :items="dataStore.colors"
              item-value="id"
              hide-details
              dense
              outlined
              ><template slot="selection" slot-scope="data">
                <span
                  class="ddl-color-only"
                  :style="colorStyle(data.item.colorValue)"
                  >&nbsp;</span
                >
              </template>
              <template slot="item" slot-scope="data">
                <span
                  class="ddl-color"
                  :style="colorStyle(data.item.colorValue)"
                ></span
                >{{ data.item.displayColor }}
              </template></v-select
            >
          </v-col>
          <v-col cols="6">
            <v-btn color="primary">X</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-card class="pa-6">
          <v-simple-table class="clearance-table pt-6">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Created</th>
                  <th>Finished</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="action in detail.actions">
                  <td>{{ action.type }}</td>
                  <td>{{ action.status }}</td>
                  <td>{{ formatDateTimeWithTime(action.created, null) }}</td>
                  <td>{{ formatDateTimeWithTime(action.finished, null) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="detail.automaticClearance.contactperson"
          label="Contact person"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="detail.automaticClearance.department"
          label="Department"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="detail.automaticClearance.uniqueReference"
          label="Unique reference"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="detail.automaticClearance.costumerRemark"
          label="Customer remarks"
          outlined
          hide-details
          auto-grow
          row-height="10"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { formatDateTimeWithTime } from "@/helpers/dateTimeHelper";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}
defineProps<IProps>();
const dataStore = useDataStore();

const colorStyle = (color: string) => {
  return "background-color:" + color;
};
</script>

<style lang="scss">
.ddl-color {
  display: inline-block;
  border: solid 1px rgba(0, 0, 0, 0.87);
  margin-right: 8px;
  width: 32px;
  height: 16px;
}
.ddl-color-only {
  display: block;
  border: solid 1px rgba(0, 0, 0, 0.87);
  width: 32px;
  height: 16px;
}
</style>
