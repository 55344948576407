<template>
  <v-card class="pa-3 sendung-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Sendungsinformationen</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="detail.importShipmentInfo.registration"
          label="Verfahren"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.importShipmentInfo.customsStatus"
          label="Zollrechtlicher Status"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.typeOfBusiness"
          :items="dataStore.businessTypes"
          item-value="id"
          hide-details
          dense
          label="Geschäftsart"
          outlined
          ><template slot="selection" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template></v-select
        >
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.countryOfOrigin"
          :items="dataStore.originCountries"
          item-value="code"
          hide-details
          dense
          label="Ursprungsland"
          outlined
          ><template slot="selection" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template></v-select
        >
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.departureAirport"
          :items="dataStore.airports"
          item-value="key"
          item-text="value"
          hide-details
          dense
          label="Abflughafen"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.importShipmentInfo.freightCostsPerc"
          label="Frachtkosten %"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.destinationCountryEU"
          :items="dataStore.euDestinationCountries"
          item-value="id"
          item-text="description"
          hide-details
          dense
          label="Bestimmungs EU land"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.destinationCountry"
          :items="dataStore.destinationCountries"
          item-value="id"
          item-text="description"
          hide-details
          dense
          label="Bestimmungsbundesland"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.deliveryConditionsLocation"
          :items="dataStore.deliveryConditions"
          item-value="id"
          item-text="code"
          hide-details
          dense
          label="Lieferbedingung"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.importShipmentInfo.deliveryConditionsLocationOrt"
          label="Lieferbedingungen Ort"
          dense
          hide-details
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.statisticalStatus"
          :items="statisticalStatusses"
          hide-details
          dense
          label="Statistischer Status"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="detail.importShipmentInfo.preTaxDeduction"
          label="Vorsteuerabzug"
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.customsTransportType"
          :items="dataStore.customsTransportType"
          item-value="id"
          hide-details
          dense
          label="Verkehrsmittel"
          outlined
          ><template slot="selection" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template>
          <template slot="item" slot-scope="data">
            {{ data.item.code }} - {{ data.item.description }}
          </template></v-select
        >
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="detail.importShipmentInfo.customsCode"
          :items="dataStore.codes"
          item-value="id"
          item-text="name"
          hide-details
          dense
          label="Code"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="detail.importShipmentInfo.additionalText"
          label="Positionszusatz"
          outlined
          hide-details
          auto-grow
          row-height="15"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}
defineProps<IProps>();
const dataStore = useDataStore();

const statisticalStatusses = ref<string[]>(["01", "03", "04"]);
</script>
