<template>
  <v-card class="pa-3 invoice-card">
    <v-toolbar class="elevation-0" dense>
      <v-card-title>Financial</v-card-title>
    </v-toolbar>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.chargesZoll"
          label="Charges 'Zoll'"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.chargesEUST"
          label="Charges EUST"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.financial.chargesOther"
          label="Charges 'other' + VAT"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="detail.financial.chargesOtherRemark"
          label="text"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.financial.chargesOtherNoVAT"
          label="Charges 'other' + w/o VAT"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="detail.financial.chargesOtherNoVatRemark"
          label="text"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.financial.eza"
          label="Abfertig. EZA"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="detail.financial.t1"
          label="T1"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="detail.financial.clearanceByCustomerCosts"
          label="Zessionsgeb."
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.financial.ccCharges"
          label="CC Charges"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-checkbox
          v-model="detail.financial.invoiceDoNotShowCC"
          hide-details
          class="ma-0"
          label="Not on invoice"
        ></v-checkbox>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.financial.additionalFreightCosts"
          label="Zus. Frachtkost."
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-checkbox
          v-model="detail.financial.invoiceDoNotShowAddFrCosts"
          hide-details
          class="ma-0"
          label="Not on invoice"
        ></v-checkbox>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.financial.storageCosts"
          label="Lagerkosten"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-checkbox
          v-model="detail.financial.invoiceDoNotShowLager"
          hide-details
          class="ma-0"
          label="Not on invoice"
        ></v-checkbox>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.financial.customsInspectionCosts"
          label="Beschaukosten"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-checkbox
          v-model="detail.financial.invoiceDoNotShowBeschau"
          hide-details
          class="ma-0"
          label="Not on invoice"
        ></v-checkbox>
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="detail.financial.financialSurcharge"
          label="Vorlageentgelt"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.summeOhneUST"
          label="Summe o. UST"
          type="number"
          outlined
          disabled
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.ust"
          label="Umsatzsteuer"
          type="number"
          outlined
          disabled
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.summeMitUST"
          label="Summe m. UST"
          type="number"
          outlined
          disabled
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.totalAmount"
          label="Total amount"
          type="number"
          outlined
          disabled
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.invoiceAmount"
          label="Invoice amount"
          type="number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.invoiceNumber"
          label="Invoice number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="detail.financial.przNumber"
          label="PRZ number"
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-menu
          v-model="showBundesKasseGebuchtAm"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="bundesKasseGebuchtAm"
              label="B.kasse gebucht"
              prepend-icon="mdi-calendar"
              outlined
              hide-details
              dense
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="bundesKasseGebuchtAm"
            @input="showBundesKasseGebuchtAm = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6 checkbox-no-mt">
        <v-checkbox
          v-model="detail.financial.proformaInvoice"
          hide-details
          label="Proforma invoice"
        ></v-checkbox>
      </v-col>
      <v-col cols="6 checkbox-no-mt">
        <v-checkbox
          v-model="detail.financial.credit"
          hide-details
          label="credit"
        ></v-checkbox>
      </v-col>
      <v-col cols="6 checkbox-no-mt">
        <v-checkbox
          v-model="detail.financial.invoiceAddressChecked"
          hide-details
          label="Invoice address checked"
        ></v-checkbox>
      </v-col>
      <v-col cols="6 checkbox-no-mt">
        <v-checkbox
          v-model="detail.financial.deliveryAddressChecked"
          hide-details
          label="Delivery address checked"
        ></v-checkbox>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="detail.financial.invoiceEmailAddress"
          label="Inv. email. addr."
          outlined
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-select
          :items="sendByDDL"
          v-model="detail.financial.sendBy"
          hide-details
          dense
          label="Send by"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary">Pre-alert</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ShipmentDetailViewModel } from "@/openapi";
import { sendByDDL } from "@/helpers/shipmentDetailHelper";
import { useDataStore } from "@/stores/data-store";

interface IProps {
  detail: ShipmentDetailViewModel;
}
const props = defineProps<IProps>();
const dataStore = useDataStore();

const showBundesKasseGebuchtAm = ref(false);
const bundesKasseGebuchtAm = computed({
  get() {
    return props.detail.financial.bundesKasseGebuchtAm
      ? props.detail.financial.bundesKasseGebuchtAm.substr(0, 10)
      : props.detail.financial.bundesKasseGebuchtAm;
  },
  set(newValue) {
    props.detail.financial.bundesKasseGebuchtAm = newValue;
  },
});
</script>
