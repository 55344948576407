<template>
  <div
    class="container container--fluid shipment-detail-page"
    :class="{ disabled: isLoading }"
  >
    <div class="row">
      <div class="col col-12">
        <div class="arrow-pointer"></div>
        <v-card :loading="isLoading">
          <v-toolbar flat class="grey darken-3" color="white" dark>
            <v-toolbar-title class=""
              ><b>HAWB:</b> {{ selectedShipment?.references?.hawbNumber }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4"
              @click="deletingShipment = true"
              color="error"
              elevation="2"
              ><v-icon small color="white">mdi-trash-can-outline</v-icon> Delete
              shipment</v-btn
            >
            <v-btn
              class="mr-4"
              @click="saveCurrentShipment()"
              color="success"
              elevation="2"
              ><v-icon color="white">mdi-floppy</v-icon> Save shipment</v-btn
            >
            <v-select
              :items="shipments"
              item-text="references.hawbNumber"
              item-value="id"
              v-model="selectedId"
              dense
              outlined
              label="Select shipment"
              class="mr-4"
              hide-details
            ></v-select>
            <v-btn
              class="mr-4"
              color="primary"
              :disabled="tab == 0"
              @click="previousTab"
            >
              <v-icon class="mr-1"> mdi-chevron-left </v-icon>
              Previous shipment
            </v-btn>
            <v-btn
              class="mr-4"
              color="primary"
              :disabled="!hasNextTab"
              @click="nextTab"
            >
              Next shipment
              <v-icon class="ml-1"> mdi-chevron-right </v-icon>
            </v-btn>
          </v-toolbar>
          <v-tabs vertical v-model="selectedShipmentTab">
            <v-tab class="justify-start">
              <v-icon left> mdi-folder-text-outline </v-icon>
              General
            </v-tab>
            <v-tab class="justify-start">
              <v-icon left> mdi-file-document </v-icon>
              Documents
            </v-tab>
            <v-tab class="justify-start">
              <v-icon left> mdi-receipt-text </v-icon>
              Invoicing
            </v-tab>
            <v-tab class="justify-start">
              <v-icon left> mdi-import </v-icon>
              Import
            </v-tab>
            <v-tab class="justify-start">
              <v-icon left> mdi-export </v-icon>
              Export
            </v-tab>
            <v-tab class="justify-start">
              <v-icon left> mdi-information-outline </v-icon>
              System information
            </v-tab>

            <v-tab-item class="grey lighten-4 pa-5">
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(shipment, index) in shipments" :key="index">
                  <div class="v-card v-sheet theme--light grey darken-3">
                    <ShipmentDetailsGeneral
                      :detail.sync="shipment"
                    ></ShipmentDetailsGeneral>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
            <v-tab-item class="grey lighten-4 pa-5 shipment-detail-docs">
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(shipment, index) in shipments" :key="index">
                  <div class="v-card v-sheet theme--light grey darken-3">
                    <ShipmentDetailsDocs
                      :documents.sync="shipment.documents"
                      :id="shipment.id"
                    ></ShipmentDetailsDocs>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
            <v-tab-item class="grey lighten-4 pa-5">
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(shipment, index) in shipments" :key="index">
                  <div class="v-card v-sheet theme--light grey darken-3">
                    <div class="d-flex justify-space-between pa-4">
                      <h1>
                        Shipment #{{ index + 1 }} ({{
                          shipment.references.hawbNumber
                        }})
                      </h1>
                      <div class="mt-4">
                        <v-btn class="mr-4" color="error" elevation="2"
                          ><v-icon small color="white"
                            >mdi-trash-can-outline</v-icon
                          >
                          Delete shipment</v-btn
                        >
                        <v-btn
                          @click="saveShipment(shipment, index)"
                          color="success"
                          elevation="2"
                          ><v-icon color="white">mdi-floppy</v-icon> Save
                          shipment</v-btn
                        >
                      </div>
                    </div>
                    <ShipmentDetailsInvoice
                      :detail.sync="shipment"
                    ></ShipmentDetailsInvoice>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
            <v-tab-item class="grey lighten-4 pa-5">
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(shipment, index) in shipments" :key="index">
                  <div class="v-card v-sheet theme--light grey darken-3">
                    <div class="d-flex justify-space-between pa-4">
                      <h1>
                        Shipment #{{ index + 1 }} ({{
                          shipment.references.hawbNumber
                        }})
                      </h1>
                      <div class="mt-4">
                        <v-btn class="mr-4" color="error" elevation="2"
                          ><v-icon small color="white"
                            >mdi-trash-can-outline</v-icon
                          >
                          Delete shipment</v-btn
                        >
                        <v-btn
                          @click="saveShipment(shipment, index)"
                          color="success"
                          elevation="2"
                          ><v-icon color="white">mdi-floppy</v-icon> Save
                          shipment</v-btn
                        >
                      </div>
                    </div>
                    <ShipmentDetailsImport
                      :detail.sync="shipment"
                    ></ShipmentDetailsImport>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
            <v-tab-item class="grey lighten-4 pa-5"> Export </v-tab-item>
            <v-tab-item class="grey lighten-4 pa-5">
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(shipment, index) in shipments" :key="index">
                  <div class="v-card v-sheet theme--light grey darken-3">
                    <div class="d-flex justify-space-between pa-4">
                      <h1>
                        Shipment #{{ index + 1 }} ({{
                          shipment.references.hawbNumber
                        }})
                      </h1>
                      <div class="mt-4">
                        <v-btn class="mr-4" color="error" elevation="2"
                          ><v-icon small color="white"
                            >mdi-trash-can-outline</v-icon
                          >
                          Delete shipment</v-btn
                        >
                        <v-btn
                          @click="saveShipment(shipment, index)"
                          color="success"
                          elevation="2"
                          ><v-icon color="white">mdi-floppy</v-icon> Save
                          shipment</v-btn
                        >
                      </div>
                    </div>
                    <ShipmentDetailsSysInfo
                      :detail.sync="shipment"
                    ></ShipmentDetailsSysInfo>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-tab-item>
          </v-tabs>
        </v-card>
        <ConfirmDialog
          v-if="deletingShipment"
          v-model="deletingShipment"
          color="error"
          @confirm="deleteCurrentShipment"
          @close="deletingShipment = false"
        >
          <slot name="confirm-delete-text" :default-text="confirmDeleteText">{{
            confirmDeleteText
          }}</slot>
        </ConfirmDialog>
      </div>
    </div>
    <v-snackbar :timeout="5000" v-model="showSuccessMessage">
      Template has been added

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          v-bind="attrs"
          @click="showSuccessMessage = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
import {
  SchemeApi,
  ShipmentDetailApi,
  ShipmentDetailViewModel,
} from "@/openapi";
import { onMounted, ref, computed } from "vue";
import { useDataStore } from "@/stores/data-store";
import ShipmentDetailsGeneral from "./ShipmentDetailsGeneral.vue";
import ShipmentDetailsDocs from "./ShipmentDetailsDocs.vue";
import ShipmentDetailsInvoice from "./ShipmentDetailsInvoice.vue";
import ShipmentDetailsImport from "./ShipmentDetailsImport.vue";
import ShipmentDetailsSysInfo from "./ShipmentDetailsSysInfo.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue.html";
import router from "@/router";
import { emitError } from "@/event-bus";

const dataStore = useDataStore();

interface IProps {
  shipmentIds: number[];
}
const api = new ShipmentDetailApi(undefined, "");

const confirmDeleteText = "Are you sure you want to delete this shipment?";
const selectedShipmentTab = ref(0);
const tab = ref(0);
const isLoading = ref(false);
const showSuccessMessage = ref(false);
const deletingShipment = ref(false);

const props = defineProps<IProps>();
const emits = defineEmits(["PageInfoReceived"]);

const shipments = ref<ShipmentDetailViewModel[]>([]);

const selectedShipment = computed(() => {
  return shipments.value.find((item, index) => index == tab.value) ?? null;
});

const selectedId = computed({
  get() {
    return selectedShipment.value?.id ?? null;
  },
  set(newValue) {
    tab.value = shipments.value.findIndex((item) => item.id == newValue) ?? 0;
  },
});

const getShipments = async () => {
  const response = await api.getShipmentDetails(props.shipmentIds);
  dataStore.fetchAirports();
  dataStore.fetchCurrencies();
  dataStore.fetchIncoterms();
  dataStore.fetchGeneralCustomers();
  dataStore.fetchOriginCountries();
  dataStore.fetchMilestoneCustomsHandling();
  dataStore.fetchEuDestinationCountries();
  dataStore.fetchDestinationCountries();
  dataStore.fetchDocumentTypeCustoms();
  dataStore.fetchCustomsOffices();
  dataStore.fetchBusinessTypes();
  dataStore.fetchWarehouseLocations();
  dataStore.fetchDeliveryConditions();
  dataStore.fetchCustomsTransportType();
  dataStore.fetchCustomsSchemes();
  dataStore.fetchCodes();
  dataStore.fetchColors();
  dataStore.fetchCustomsHandlingCategories();
  dataStore.fetchAcDocumentTypes();
  dataStore.fetchMeasurementUnits();
  dataStore.fetchtCustomsArticles();
  dataStore.fetchtExchangeRateCurrencies();
  shipments.value = response.data ?? [];
};

const hasNextTab = computed(() => {
  return shipments.value.length > tab.value + 1;
});

const nextTab = () => {
  if (shipments.value.length > tab.value) {
    tab.value++;
  }
};

const previousTab = () => {
  if (tab.value > 0) {
    tab.value--;
  }
};

const saveShipment = async (
  shipment: ShipmentDetailViewModel,
  index: number,
) => {
  isLoading.value = true;
  const response = await api.saveShipmentDetails(shipment);
  shipments.value[index] = response.data;
  isLoading.value = false;
};

const saveCurrentShipment = async () => {
  const shipment = shipments.value[tab.value];
  saveShipment(shipment, tab.value);
};

const deleteCurrentShipment = async () => {
  isLoading.value = true;
  try {
    const shipment = shipments.value[tab.value];
    const response = await api.deleteShipmentDetails(shipment.id);
    if (response.data) {
      shipments.value.splice(tab.value, 1);
      if (shipments.value.length == 0) {
        const next = {
          name: "Shipment details",
        };
        router.push(next);
      } else if (tab.value >= shipments.value.length) {
        tab.value = shipments.value.length - 1;
      }
    } else {
      emitError("Unable to delete shipment");
    }
  } catch {
    emitError("Something went wrong while deleting shipment");
  }
  deletingShipment.value = false;
  isLoading.value = false;
};

onMounted(async () => {
  isLoading.value = true;
  await getShipments();
  isLoading.value = false;
});
</script>

<style lang="scss">
.shipment-detail-page {
  .shipment-detail-docs {
    min-height: calc(100vh - 152px);
    .shipment-detail-docs-container .v-card {
      min-height: calc(100vh - 224px);
    }
  }
}

.documents-table {
  tr {
    td:last-child {
      text-align: end;
      padding-right: 0;
    }
  }
}

.shipment-detail-page .v-label {
  //vuetify override
  top: 7px !important;
}

.shipment-detail-page .v-input__slot {
  //vuetify override
  min-height: 32px !important;
}

.shipment-detail-page .v-input--selection-controls__input + .v-label {
  //vuetify override
  top: 0 !important;
}

.checkbox-container {
  padding-left: 14px;
}

.order-details-card,
.invoice-card,
.dpd-card {
  .v-input--selection-controls {
    .v-input__control {
      padding-right: 30px;
    }
  }
}
.shipment-detail-page .v-card__title {
  padding-left: 0;
}

.delivery-card {
  padding: 0;
}
</style>
